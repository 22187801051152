.modal-footerCustom {
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  width: 100%;
}

.noMargins {
  margin: 0;
  padding: 0;
}

.version {
  font-size: 12px;
}

.centrar {
  display: flex;
  align-items: center;
  justify-content: center !important;
  font-size: 13px;
  color: gray;
}

.customFooter {
  font-size: 13px;
}

.ui-dialog.ui-widget .ui-dialog-titlebar {
  padding: 0.6rem !important;
}
