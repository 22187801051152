body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.terminoYCondiciones {
  font-size: 13px;
}

.cursor-pointer {
  cursor: pointer;
}

.icono-tamanio {
  font-size: 17px !important;
}

/* Define css class for global loading message to cover 
   screen during axios operations */

.spinner:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
}

.spinner:after {
  content: "";
  width: 100%;
  position: fixed; /*it can be fixed too*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1001;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.caja-transparente-blanca {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 15px;
  position: relative;
}

legend {
  width: auto !important;
  font-size: 1rem !important;
  margin: 0 !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* sm personalizado */
@media only screen and (max-width: 600px) {
  input {
    font-size: 16px !important;
  }
}
